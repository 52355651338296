import React from "react"
import "../../assets/css/common.css"
import { t } from '../../components/i18n/i18n';
import back from "../../assets/images/elements/timeHeader.jpg";

export const Schedule = () => {
  return (
    <>
        <>

        <section className='blog'>
          <div className='container_100'>

              <div className='backImg15'>
                <img src={back} alt='' />
                <div className='text_container_dark_15'>
                  <div className='h1_15'>{t("scheduleHeader")}</div>
                </div>

              </div>
              <br/>

              <div className='container boxItems'>

                    <div className='container boxItems scheduleForm'>

                        <div className='article_grid'>
                            <div>

                                <h2 className='underline_red'>{t("tuesday")}</h2>

                                <p><strong>{t("scheduleTueTime1")}</strong> : {t("scheduleTueDescription1")}</p>
                                <p><strong>{t("scheduleTueTime2")}</strong> : {t("scheduleTueDescription2")}</p>
                                <p><strong>{t("scheduleTueTime3")}</strong> : {t("scheduleTueDescription3")}</p>

                                <br/>
                                <h2 className='underline_red'>{t("wednesday")}</h2>
                                <p><strong>{t("scheduleWedTime1")}</strong> : {t("scheduleWedDescription1")}</p>

                                <br/>
                                <h2 className='underline_red'>{t("friday")}</h2>
                                <p><strong>{t("scheduleFriTime1")}</strong> : {t("scheduleFriDescription1")}</p>

                            </div>
                            <div>

                                <h2 className='underline_red'>{t("saturday")}</h2>
                                <p><strong>{t("scheduleSatTime1")}</strong> : {t("scheduleSatDescription1")}</p>
                                <p><strong>{t("scheduleSatTime2")}</strong> : {t("scheduleSatDescription2")}</p>
                                <p><strong>{t("scheduleSatTime3")}</strong> : {t("scheduleSatDescription3")}</p>

                                <br/>
                                <h2 className='underline_red'>{t("sunday")}</h2>
                                <p><strong>{t("scheduleSunTime1")}</strong> : {t("scheduleSunDescription1")}</p>
                                <p><strong>{t("scheduleSunTime2")}</strong> : {t("scheduleSunDescription2")}</p>


                            </div>
                        </div>
                    </div>
              </div>
          </div>

        </section>
        </>
    </>
  )
}
