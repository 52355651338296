import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import "./blog.css"
import { AiOutlineClockCircle } from "react-icons/ai"
import Moment from 'moment';

import {
    mediaServiceEndpoint,
    youtubeBaseURL,
    youtubeVideoKeywordBible,
    youtubeVideoKeywordOnline
} from '../../assets/data/constants';
import { t } from '../i18n/i18n';
import { Loader } from '../Loader/Loader'
import back from "../../assets/images/elements/worshipHeader.jpg";

export default function YoutubeNews() {

    const [videos, setVideos] = useState([]);
    const [videosLabel, setVideosLabel] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const newstype = id;

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const data = await fetch(`${mediaServiceEndpoint}?action=youtubefeed`).then(response => response.json());
                setLoading(false);
                switch (newstype) {
                    case 'general':
                        setVideos(data.items.filter(item => !item.snippet.title.startsWith(youtubeVideoKeywordOnline) && !item.snippet.title.startsWith(youtubeVideoKeywordBible)).slice(0, 3));
                        setVideosLabel(t('youtubeVideosWorship'));
                        break;
                    case 'online':
                        setVideos(data.items.filter(item => item.snippet.title.startsWith(youtubeVideoKeywordOnline)).slice(0, 3));
                        setVideosLabel(t("youtubeVideosOnline"));
                        break;
                    case 'bible':
                        setVideos(data.items.filter(item => item.snippet.title.startsWith(youtubeVideoKeywordBible)));
                        setVideosLabel(t("youtubeVideosBible"));
                        break;
                    default:
                        setVideos(data.items.filter(item => !item.snippet.title.startsWith(youtubeVideoKeywordOnline) && !item.snippet.title.startsWith(youtubeVideoKeywordBible)).slice(0, 3));
                        setVideosLabel(t("youtubeVideosWorship"));
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [newstype]);

    return (
        loading ? (
            <Loader />
        ) : (
        <>
            <section className='blog'>
              <div className='container_100'>

                <div className='backImg15'>
                    <img src={back} alt='' />
                    <div className='text_container_dark_15'>
                        <div className='h1_15'>{videosLabel}</div>
                    </div>
                </div>

                <div className='container grid3'>
                    {videos.map((item) => (
                        <div className='box boxItems' key={item.guid}>
                            <a href={`${youtubeBaseURL}/watch?v=${item.id.videoId}`} target="_blank" rel="noreferrer" className='cardimg'>
                            <div className='cardimg'>
                                <img src={item.snippet.thumbnails.high.url} alt='' />
                            </div>
                            <div className='details'>
                                {item.snippet.title}
                                <br/>
                                <div className='date'>
                                    <AiOutlineClockCircle className='icon' /> <label htmlFor=''>{Moment(item.snippet.publishedAt).format('YYYY/MM/DD')}</label>
                                </div>
                            </div>
                            </a>
                        </div>
                    ))}
                </div>
              </div>

            </section>
        </>
    ))
}
