import { Blocks } from 'react-loader-spinner';
import React from "react";

/* https://mhnpd.github.io/react-loader-spinner/docs/intro/ */
export const Loader = () => {
    return (

        <div className="loader">
            <div className="loader-wrap">
                <Blocks
                  height="180"
                  width="180"
                  color="#4fa94d"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="flexCenter"
                  visible={true}
                />
            </div>
        </div>
    )
}
