import React from "react"
import "../../assets/css/common.css"

import menu from '../../assets/images/help/menu.jpg';
import language from '../../assets/images/help/language.jpg';
import menumobile from '../../assets/images/help/menumobile.jpg';
import menuweb from '../../assets/images/help/menuweb.jpg';
import menusearch from '../../assets/images/help/menusearch.jpg';
import youtube from '../../assets/images/help/youtube.jpg';
import insta from '../../assets/images/help/insta.jpg';
import search from '../../assets/images/help/search.jpg';
import searchyear from '../../assets/images/help/searchyear.jpg';
import searchauthor from '../../assets/images/help/searchauthor.jpg';
import mediaicons from '../../assets/images/help/media.jpg';
import schedulecontact from '../../assets/images/help/schedulecontact.jpg';
import back from "../../assets/images/elements/helpHeader.jpg";

import {t} from '../../components/i18n/i18n';

export const Help = () => {

    return (
        <>
            <section className='text_container_light'>
                <div className='container_100'>

                    <div className='backImg15'>
                        <img src={back} alt='' />
                        <div className='text_container_dark_15'>
                          <div className='h1_15'>{t("helpHeader")}</div>
                        </div>

                    </div>
                    <br/>

                    <div className='container boxItems'>


                        <div className='article_grid'>
                            <div>
                                <h2 className='menuColor'>{t("helpNavigation")}</h2>
                                <p>{t("helpNavP1")}</p>
                                <img className="helpimg" width='100%' src={menuweb} alt=''/>
                                <p>{t("helpNavP2")}</p>
                                <img className="helpimg" width='100%' src={menumobile} alt=''/>
                                <p>{t("helpNavP3")}</p>
                                <img className="helpimg" width='100%' src={menu} alt=''/>

                                <p>{t("helpNavP4")}</p>
                                <img className="helpimg" width='100%' src={schedulecontact} alt=''/>

                                <p>{t("helpNavP5")}</p>
                                <img className="helpimg" width='100%' src={language} alt=''/>
                                <p>{t("helpNavP6")}</p>
                                <img className="helpimg" width='100%' src={mediaicons} alt=''/>

                                <h2 className='menuColor'>{t("helpNewsStructure")}</h2>
                                <p>{t("helpNewsP1")}</p>
                                <img className="helpimg" width='100%' src={youtube} alt=''/>
                                <img className="helpimg" width='100%' src={insta} alt=''/>
                                <p>{t("helpNewsP2")}</p>

                            </div>
                            <div>

                                <h2 className='menuColor'>{t("helpSearch")}</h2>
                                <p>{t("helpSearchP1")}</p>
                                <img className="helpimg" width='100%' src={menusearch} alt=''/>
                                <p>{t("helpSearchP2")}</p>
                                <img className="helpimg" width='100%' src={searchyear} alt=''/>
                                <p>{t("helpSearchP3")}</p>
                                <img className="helpimg" width='100%' src={search} alt=''/>
                                <p>{t("helpSearchP4")}</p>
                                <img className="helpimg" width='100%' src={searchauthor} alt=''/>

                                <p>{t("helpWishes")}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
