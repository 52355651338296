import Skeleton from "@mui/material/Skeleton";
import InstaItem from "./InstaItem";
import back from "../../assets/images/elements/instaHeader.jpg";
import React from "react";
import {t} from '../../components/i18n/i18n';
import Moment from "./YoutubeNews";

interface InstaGridProps {
    items: InstaItem[];
}

const gridStyle: React.CSSProperties = {
    width: '100%',
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridTemplateRows: "repeat(3,1fr)",
    rowGap: "2%",
    columnGap: "2%"
};

const itemStyle: React.CSSProperties = {
    display: "block",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    aspectRatio: "1/ 1"
};

const InstaGrid = ({ items }: InstaGridProps) => {
    if (items.length === 0) {
        return (
            <section className='blog'>

                <div className='backImg15'>
                    <img src={back} alt='' />
                    <div className='text_container_dark_15'>
                        <div className='h1_15'>{t("menuNewsInsta")}</div>
                    </div>
                </div>

                <div style={gridStyle}>
                    {(new Array(9)).fill(<Skeleton variant='rectangular' sx={itemStyle} /> )}
                </div>
            </section>
        )
    }
    return (

            <section className='blog'>

                <div className='backImg15'>
                    <img src={back} alt='' />
                    <div className='text_container_dark_15'>
                        <div className='h1_15'>{t("menuNewsInsta")}</div>
                    </div>
                </div>

                <div className='container grid3'>
                    {items.map((item) => (
                        <div className='box boxItems' key={item.mediaUrl}>
                            <div className='cardimg'>
                                <a href={item.permalink} target="_blank" rel="noreferrer" className='cardimg'>
                                    <img src={item.mediaUrl} style={itemStyle} alt='' />
                                    <div className='details'>
                                        {item.caption}
                                    </div>

                                </a>
                            </div>
                        </div>
                    ))}
                </div>

            </section>

    );
};

export default InstaGrid;
