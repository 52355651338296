import React, { useState, useEffect } from 'react';
import InstaGrid from "./InstaGrid";
import InstaItem from "./InstaItem";
import { Loader } from '../Loader/Loader'
import {
    mediaServiceEndpoint,
} from '../../assets/data/constants';

const InstaFeed = () => {
    const [instaItems, setInstaItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchMedia = async (id: string) => {
            const mediaUrl = `${mediaServiceEndpoint}?action=instamedia&id=${id}`;

            const res = await fetch(mediaUrl);
            const json = (await res.json());

            const instaItem: InstaItem = {
                permalink: json.permalink,
                mediaUrl: json.media_url,
                caption: json.caption
            };

            return instaItem;
        };

        const doFetch = async() => {
            setLoading(true);
            const instaUrl = `${mediaServiceEndpoint}?action=instafeed`;

            const res = await fetch(instaUrl);
            const json = (await res.json()).data;
            setLoading(false);

            const fetchedItems: InstaItem[] = [];

            for (let i = 0; i < json.length && i < 9; i++) {
                const item = json[i];
                const itemId = item.id;
                const instaItem = await fetchMedia(itemId);
                fetchedItems.push(instaItem);
            }

            setInstaItems(fetchedItems);
        };

        doFetch();

    }, []);

    return (
        loading ? (
            <Loader />
        ) : (
            <InstaGrid items={instaItems} />
        )
    );

}

export default InstaFeed;
