import React, { useState, useEffect } from 'react';
import sermon from '../../assets/data/sermon';
import events from '../../assets/data/events';
import word from '../../assets/data/word';
import choir from '../../assets/data/choir';

import {t} from "../../components/i18n/i18n";
import back from "../../assets/images/elements/archiveHeader.jpg";

export const WebArchive = () => {

  const [videos, setVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedYears, setExpandedYears] = useState({});

  useEffect(() => {
    const combinedVideos = [...sermon, ...events, ...word, ...choir];
    setVideos(combinedVideos);
  }, []);

  const handleSearch = event => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    if (searchValue) {
      const years = videos.reduce((acc, video) => {
        acc[video.year] = true;
        return acc;
      }, {});
      setExpandedYears(years);
    } else {
      setExpandedYears({});
    }
  };

  const toggleYear = year => {
    setExpandedYears(prevState => ({
      ...prevState,
      [year]: !prevState[year]
    }));
  };

  const filteredVideos = videos.filter((video) =>
    video.title.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
    video.keywords.some((keyword) =>
      keyword.toLowerCase().includes(searchTerm.trim().toLowerCase())
    ) ||
    video.year.toString().includes(searchTerm) ||
    video.bible.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
    video.author.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  const videosByYearAndAuthor = filteredVideos.reduce((acc, video) => {
    acc[video.year] = acc[video.year] || {};
    acc[video.year][video.author] = acc[video.year][video.author] || [];
    acc[video.year][video.author].push(video);
    return acc;
  }, {});

  return (
      <>

      <section className='blog'>

        <div className='container_100'>
          <div className='backImg15'>
            <img src={back} alt='' />
            <div className='text_container_dark_15_top'>
              <div className='h1_15 margin0'>{t("webArchiveTitle")}</div>
              <center><input
                    type="text"
                    placeholder={t("search")}
                    value={searchTerm}
                    onChange={handleSearch}
              /></center>

            </div>
          </div>
          <br/>

          <div className='container boxItems'>
                {Object.keys(videosByYearAndAuthor).sort((a, b) => b - a).map(year => (
                  <div className='menuColor' key={year}>
                    <h1 className='webArchiveYearHeader' onClick={() => toggleYear(year)} style={{ cursor: 'pointer' }}>
                      {year} {expandedYears[year] ? '-' : '+'}
                    </h1>
                    {expandedYears[year] && (
                      Object.keys(videosByYearAndAuthor[year]).sort().map(author => (
                        <div key={author}>
                          <h4><u>{author}</u></h4>
                          <ul>
                            {videosByYearAndAuthor[year][author].map(video => (
                              <li key={video.link}>
                                <a href={video.link} target="_blank" rel="noopener noreferrer">
                                  {video.title} {video.bible && `(${video.bible})`}
                                </a>
                              </li>
                            ))}
                          </ul>
                          <br/>
                        </div>
                      ))
                    )}
                  </div>
                ))}
          </div>
        </div>

        <br/>
      </section>

      </>
  );
};
